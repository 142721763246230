






























































































































































































































































































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "Home",
  mounted() {
    this.trackMyPageView();
    this.$nextTick(() => {
      // this.currentCapability = this.warehouseFeatures[0];
    });
  },
  metaInfo: {
    title: metadata.Home.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Home.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  data: () => ({
    storageDelivery: [
      {
        pretext:
          "Transforming the logistics industry with cutting-edge technology",
        text: "Manage all your freight, delivery, warehousing, transportation, and customs clearance projects from one easy-to-use portal",
        imageUrl: require("@/assets/storage-delivery3.jpg"),
        target: { name: "Technology" },
      },
      {
        pretext:
          "Offering on-demand warehousing storage. Pay what you use, when you use it",
        text: "Our platform offers you the possibility to instantly connect with countless warehouses and storage suppliers across the UAE—giving you the space to expand with just one click",
        imageUrl: require("@/assets/Ultra-Flexible-1536x958.jpg.webp"),
        target: { name: "Warehousing" },
      },
      {
        pretext: "Handling complex projects with expertise",
        text: "Whatever your cargo requirements may be, our team is well-equipped to manage your projects with ease—no matter the size, tight deadlines, budget, or complexity of the case",
        imageUrl: require("@/assets/storage-delivery1.jpg"),
        target: { name: "ProjectManagement" },
      },
    ],
    storageDeliveryKw: [
      {
        pretext:
          "Transforming the logistics industry with cutting-edge technology",
        text: "Manage all your freight, delivery, warehousing, transportation, and customs clearance projects from one easy-to-use portal",
        imageUrl: require("@/assets/storage-delivery3.jpg"),
        target: { name: "Technology" },
      },
      {
        pretext:
          "Offering on-demand warehousing storage. Pay what you use, when you use it",
        text: "Our platform offers you the possibility to instantly connect with countless warehouses and storage suppliers across the country—giving you the space to expand with just one click",
        imageUrl: require("@/assets/Ultra-Flexible-1536x958.jpg.webp"),
        target: { name: "Warehousing" },
      },
      {
        pretext: "Handling complex projects with expertise",
        text: "Whatever your cargo requirements may be, our team is well-equipped to manage your projects with ease—no matter the size, tight deadlines, budget, or complexity of the case",
        imageUrl: require("@/assets/storage-delivery1.jpg"),
        target: { name: "ProjectManagement" },
      },
    ],
    howItWorks: [
      {
        title: "Request a Quote",
        description:
          "All supply chain management services are tailored to your requirements with costs that reflect these. For warehousing, we offer special subscription fees.",
      },
      {
        title: "Get Access to Portal",
        description:
          "Once you’ve agreed to the costs, you will be able to register to our portal.",
      },
      {
        title: "Manage your Projects Through out Platform",
        description:
          "With our state-of-the art technology, all your projects will be integrated and managed in one comprehensive platform.",
      },
    ],
    currentCapability: {} as { title: string; description: string },
    warehouseFeatures: [
      {
        title: "Freight",
      },
      {
        title: "Shipment consolidation",
      },
      {
        title: "Transportation",
      },
      {
        title: "Customs clearance",
      },
      {
        title: "Project management (special cases)",
      },
      {
        title: "Same day delivery",
      },
      {
        title: "Fulfillment",
      },
      {
        title: "Warehousing",
      },
    ],
    clients: [
      {
        image: require("@/assets/new-site/clients/client1.png"),
        height: "70",
      },
      {
        image: require("@/assets/new-site/clients/client2.png"),
        height: "70",
      },
      {
        image: require("@/assets/new-site/clients/client3.png"),
        height: "200",
      },
      {
        image: require("@/assets/new-site/clients/client4.png"),
        height: "60",
      },
      {
        image: require("@/assets/new-site/clients/client5.png"),
        height: "100",
      },
      {
        image: require("@/assets/new-site/clients/client6.png"),
        height: "220",
      },
      {
        image: require("@/assets/new-site/clients/client7.png"),
        height: "160",
      },
      {
        image: require("@/assets/new-site/clients/client8.png"),
        height: "160",
      },
      {
        image: require("@/assets/new-site/clients/client9.png"),
        height: "120",
      },
      {
        image: require("@/assets/new-site/clients/client10.jpg"),
        height: "140",
      },
      {
        image: require("@/assets/new-site/clients/client11.png"),
        height: "120",
      },
      {
        image: require("@/assets/new-site/clients/client12.jpeg"),
        height: "160",
      },
    ],
    westoreLogistics: [
      {
        title: "On Time Shipping",
        desc: "When using Westore delivery services",
        image: require("@/assets/new-site/home/logistics1.png"),
        number: "99%",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Order Accuracy",
        desc: "Improving customer satisfaction",
        image: require("@/assets/new-site/home/logistics2.png"),
        number: "99%",
        height: "80",
        mobileHeight: "55",
      },
      {
        title: "Picking Accuracy",
        desc: "Resulting in improved user experience",
        image: require("@/assets/new-site/home/logistics3.png"),
        number: "98%+",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Platform Integration",
        desc: "In less than 1 minute",
        image: require("@/assets/new-site/home/logistics4.png"),
        number: "3-Click",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Storage Spaces",
        desc: "Mainland and freeze ones",
        image: require("@/assets/new-site/home/logistics5.png"),
        number: "3 Million sq. ft",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Warehouse Locations",
        desc: "Across the GCC",
        image: require("@/assets/new-site/home/logistics6.png"),
        number: "160 +",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Cost Reduction",
        desc: "Compared to traditional warehouses",
        image: require("@/assets/new-site/home/logistics7.png"),
        number: "15% +",
        height: "60",
        mobileHeight: "55",
      },
      {
        title: "Increase in E-commerce",
        desc: "Conversion rate with our next day delivery service",
        image: require("@/assets/new-site/home/logistics8.png"),
        number: "25%",
        height: "60",
        mobileHeight: "55",
      },
    ],

    westoreLogisticsKw: [
      {
        title: "On Time Shipping",
        desc: "When using Westore delivery services",
        image: require("@/assets/new-site/home/logistics1.png"),
        number: "99%",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Order Accuracy",
        desc: "Improving customer satisfaction",
        image: require("@/assets/new-site/home/logistics2.png"),
        number: "99%",
        height: "80",
        mobileHeight: "55",
      },
      {
        title: "Picking Accuracy",
        desc: "Resulting in improved user experience",
        image: require("@/assets/new-site/home/logistics3.png"),
        number: "98%+",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Platform Integration",
        desc: "In less than 1 minute",
        image: require("@/assets/new-site/home/logistics4.png"),
        number: "3-Click",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Storage Spaces",
        desc: "Temperature controlled and Ampient",
        image: require("@/assets/new-site/home/logistics5.png"),
        number: "28,000  SQM",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Warehouse Locations",
        desc: "Across kuwait",
        image: require("@/assets/new-site/home/logistics6.png"),
        number: "4",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Cost Reduction",
        desc: "Compared to traditional warehouses",
        image: require("@/assets/new-site/home/logistics7.png"),
        number: "15% +",
        height: "60",
        mobileHeight: "55",
      },
      {
        title: "Increase in E-commerce",
        desc: "Conversion rate with our next day delivery service",
        image: require("@/assets/new-site/home/logistics8.png"),
        number: "25%",
        height: "60",
        mobileHeight: "55",
      },
    ],
    westoreLogisticsQa: [
      {
        title: "On Time Shipping",
        desc: "When using Westore delivery services",
        image: require("@/assets/new-site/home/logistics1.png"),
        number: "99%",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Order Accuracy",
        desc: "Improving customer satisfaction",
        image: require("@/assets/new-site/home/logistics2.png"),
        number: "99%",
        height: "80",
        mobileHeight: "55",
      },
      {
        title: "Picking Accuracy",
        desc: "Resulting in improved user experience",
        image: require("@/assets/new-site/home/logistics3.png"),
        number: "98%+",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Platform Integration",
        desc: "In less than 1 minute",
        image: require("@/assets/new-site/home/logistics4.png"),
        number: "3-Click",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Storage Spaces",
        desc: "Temperature controlled and Ampient",
        image: require("@/assets/new-site/home/logistics5.png"),
        number: "25,000  SQM",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Warehouse Locations",
        desc: "Across Qatar",
        image: require("@/assets/new-site/home/logistics6.png"),
        number: "3",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Cost Reduction",
        desc: "Compared to traditional warehouses",
        image: require("@/assets/new-site/home/logistics7.png"),
        number: "15% +",
        height: "60",
        mobileHeight: "55",
      },
      {
        title: "Increase in E-commerce",
        desc: "Conversion rate with our next day delivery service",
        image: require("@/assets/new-site/home/logistics8.png"),
        number: "25%",
        height: "60",
        mobileHeight: "55",
      },
    ],
    swiperOptions: {
      speed: 1500,
      loop: true,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 1,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 1,
        },
      },
    },
    featureCardVisible: true,
  }),
  watch: {
    currentCapability(newVal: any) {
      this.featureCardVisible = false;
      setTimeout(() => {
        this.featureCardVisible = true;
      }, 150);
    },
  },
  methods: {
    showHideContactsCustom() {
      if (!this.globals.whatsappPopupVisible) {
        this.logGAEvent("Let's Chat", "Whatsapp open", `Home Page`);
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    },
  },
  computed: {
    types() {
      return types;
    },
  },
});

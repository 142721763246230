<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79"
    height="70"
    viewBox="0 0 79 70"
    fill="none"
  >
    <path
      d="M25.8208 31.3448H65.0977C67.4558 31.3448 69.379 29.4299 69.379 27.069V4.27653C69.379 1.91936 67.4578 0.000638092 65.0977 0.000638092L25.8208 0C23.4627 0 21.5396 1.91677 21.5396 4.27589V27.0683C21.5396 29.4279 23.4607 31.3448 25.8208 31.3448ZM24.3935 4.27587C24.3935 3.48847 25.035 2.84846 25.8208 2.84846H65.0977C65.8835 2.84846 66.525 3.48911 66.525 4.27587V5.81814H24.3941L24.3935 4.27587ZM24.3935 14.3661H66.5244V27.0686C66.5244 27.8579 65.883 28.496 65.0971 28.496H25.822C25.0361 28.496 24.3947 27.858 24.3947 27.0686L24.3935 14.3661Z"
      fill="#C6C9CC"
    />
    <path
      d="M27.4355 19.0576H35.9559V20.9572H27.4355V19.0576Z"
      fill="#C6C9CC"
    />
    <path d="M38.7256 19.0576H47.246V20.9572H38.7256V19.0576Z" fill="#C6C9CC" />
    <path
      d="M27.4355 22.6201H31.4165V24.5178H27.4355V22.6201Z"
      fill="#C6C9CC"
    />
    <path d="M32.71 22.6201H36.6954V24.5178H32.71V22.6201Z" fill="#C6C9CC" />
    <path
      d="M37.9863 22.6201H41.9717V24.5178H37.9863V22.6201Z"
      fill="#C6C9CC"
    />
    <path
      d="M43.2646 22.6201H47.2456V24.5178H43.2646V22.6201Z"
      fill="#C6C9CC"
    />
    <path
      d="M55.4073 25.5248C56.1318 25.5248 56.7962 25.3078 57.3713 24.955C57.9418 25.3078 58.6107 25.5248 59.3353 25.5248C61.3995 25.5248 63.0754 23.8485 63.0754 21.7868C63.0754 19.7251 61.3989 18.0488 59.3353 18.0488C58.6108 18.0488 57.9425 18.2658 57.3713 18.6186C56.7963 18.2658 56.1318 18.0488 55.4073 18.0488C53.3391 18.0488 51.6626 19.7251 51.6626 21.7868C51.6626 23.8485 53.341 25.5248 55.4073 25.5248ZM55.4073 19.477C55.7158 19.477 56.0059 19.5408 56.2723 19.6474C55.8488 20.258 55.5906 20.9938 55.5906 21.7894C55.5906 22.587 55.8481 23.3208 56.2723 23.9314C56.0059 24.0418 55.7146 24.1037 55.4073 24.1037C54.1295 24.1037 53.09 23.0655 53.09 21.7894C53.09 20.5125 54.1314 19.477 55.4073 19.477Z"
      fill="#C6C9CC"
    />
    <path
      d="M64.7741 44.9194C63.7218 45.9365 62.5482 46.8151 61.2914 47.5846C61.4914 48.0376 61.6364 48.5143 61.6875 49.0248C61.954 51.7947 59.8851 54.2839 56.4282 55.3623C54.9268 55.8326 53.1328 56.0578 50.9413 56.0578C49.7721 56.0578 48.4789 55.9959 46.9858 55.8645C43.0923 55.5244 37.6797 52.884 34.8698 51.3844C36.8465 52.2586 40.5975 53.4564 47.143 54.0306C50.7131 54.3413 53.6374 54.3049 55.8806 53.6049C61.3719 51.884 61.0843 46.5571 55.6525 45.9421C48.5384 45.1381 43.5965 43.1364 41.0996 41.1322C37.7172 38.4159 32.4917 37.4454 26.7511 39.0859C26.6342 39.1178 26.4975 39.1517 26.3723 39.1861C23.2557 40.0284 18.048 40.9243 14.9487 41.4221V60.887H23.062C26.7511 60.887 29.3155 65.0825 33.825 67.95C38.3343 70.8157 45.6108 70.2005 51.3535 68.971C57.0894 67.7414 71.44 50.9566 76.7687 45.2229C82.0927 39.496 71.7259 38.2001 64.7734 44.9186L64.7741 44.9194Z"
      fill="#C6C9CC"
    />
    <path
      d="M9.94122 38.2822H2.4604C1.09955 38.2822 0 39.3842 0 40.7395V61.807C0 63.1642 1.10146 64.2616 2.4604 64.2616H9.94122C11.2982 64.2616 12.4036 63.1616 12.4036 61.807V40.7395C12.4036 39.3842 11.2964 38.2822 9.94122 38.2822ZM8.76309 59.7629C8.76309 60.4419 8.20917 60.9925 7.53194 60.9925H4.86971C4.188 60.9925 3.63856 60.4419 3.63856 59.7629V57.1015C3.63856 56.4251 4.18802 55.8719 4.86971 55.8719H7.53194C8.20917 55.8719 8.76309 56.4251 8.76309 57.1015V59.7629Z"
      fill="#C6C9CC"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

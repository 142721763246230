<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clip-path="url(#clip0_171_2221)">
      <path
        d="M6 11.25C4.60761 11.25 3.27226 10.6969 2.28769 9.71231C1.30312 8.72774 0.75 7.39239 0.75 6C0.75 4.60761 1.30312 3.27226 2.28769 2.28769C3.27226 1.30312 4.60761 0.75 6 0.75C7.39239 0.75 8.72774 1.30312 9.71231 2.28769C10.6969 3.27226 11.25 4.60761 11.25 6C11.25 7.39239 10.6969 8.72774 9.71231 9.71231C8.72774 10.6969 7.39239 11.25 6 11.25ZM6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12Z"
        fill="#FF323E"
      />
      <path
        d="M5.25146 8.25009C5.25146 8.1516 5.27086 8.05407 5.30856 7.96307C5.34625 7.87208 5.40149 7.7894 5.47113 7.71976C5.54078 7.65011 5.62346 7.59487 5.71445 7.55718C5.80545 7.51949 5.90297 7.50009 6.00146 7.50009C6.09996 7.50009 6.19748 7.51949 6.28848 7.55718C6.37947 7.59487 6.46215 7.65011 6.53179 7.71976C6.60144 7.7894 6.65668 7.87208 6.69437 7.96307C6.73207 8.05407 6.75146 8.1516 6.75146 8.25009C6.75146 8.449 6.67245 8.63977 6.53179 8.78042C6.39114 8.92107 6.20038 9.00009 6.00146 9.00009C5.80255 9.00009 5.61179 8.92107 5.47113 8.78042C5.33048 8.63977 5.25146 8.449 5.25146 8.25009ZM5.32496 3.74634C5.31498 3.65172 5.32499 3.55606 5.35437 3.46556C5.38374 3.37506 5.43181 3.29175 5.49545 3.22103C5.5591 3.15031 5.63691 3.09376 5.72382 3.05505C5.81074 3.01634 5.90482 2.99634 5.99996 2.99634C6.09511 2.99634 6.18919 3.01634 6.27611 3.05505C6.36302 3.09376 6.44083 3.15031 6.50448 3.22103C6.56812 3.29175 6.61619 3.37506 6.64556 3.46556C6.67494 3.55606 6.68495 3.65172 6.67496 3.74634L6.41247 6.37659C6.40365 6.47992 6.35637 6.57617 6.27998 6.64631C6.2036 6.71646 6.10367 6.75538 5.99996 6.75538C5.89626 6.75538 5.79633 6.71646 5.71995 6.64631C5.64356 6.57617 5.59628 6.47992 5.58746 6.37659L5.32496 3.74634Z"
        fill="#FF323E"
      />
    </g>
    <defs>
      <clipPath id="clip0_171_2221">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>

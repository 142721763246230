<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.9163 10.8942L8.40638 16.5894C7.63925 17.2615 6.4375 16.7153 6.4375 15.6952V4.30473C6.4373 4.07644 6.50292 3.85293 6.62648 3.66097C6.75004 3.46901 6.92632 3.31674 7.1342 3.22238C7.34208 3.12803 7.57275 3.0956 7.79859 3.12897C8.02442 3.16234 8.23586 3.2601 8.40756 3.41054L14.9151 9.10579C15.0426 9.21725 15.1448 9.35471 15.2149 9.50894C15.2849 9.66317 15.3212 9.83059 15.3212 9.99998C15.3212 10.1694 15.2849 10.3368 15.2149 10.491C15.1448 10.6452 15.0426 10.7827 14.9151 10.8942H14.9163Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

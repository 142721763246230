import Vue from "vue";
import { isProduction } from "./siteBase";

export const globals = {
  whatsappPopupVisible: false,
  interestDialog: false,
  showGetQuote: false,
  contactPoints: {
    phone: {
      text:"+971585989361",
      text2:"+96522277313",
      text3:"+97444131770 / 44858600",
      href: "tel:+971585989361",
      href2: "tel:+96522277313",
      href3: "tel:+97444131770",

      hrefWhatsapp: "https://wa.me/+971585989361",
      icon: "mdi-phone",
      iconWhatsapp: "mdi-whatsapp",
    },
    email: {
      text: "connect@westore.ai",
      href: "mailto:connect@westore.ai",
      icon: "mdi-email",
    },
    stAddress: {
      text: "30th floor, Office 30-11, Reef Tower Jumeirah Lakes Towers Dubai, UAE ",
      text2:"14th Floor, Al-Soor Tower Al-Soor Street P.O. Box 5110, Safat 13052 Kuwait",
      text3:"Zone no.55, Al Amir Street no. 251, Building no. 125 P.O.Box: 22403,Doha, Qatar",
      href: "https://goo.gl/maps/qm4ZKj8tmvaTXDdZ9",
      href2: "https://www.google.com/maps?q=29.361045837402344,47.966976165771484&z=14",
      icon: "mdi-map-marker ",
    },
    fax:{
      text3:"+97444369529",
      icon: "mdi-fax",

    }
  },
  dynamicUrls: {
    baseUrls: isProduction()
      ? {
          portal: "https://portal.westore.ai/",
        }
      : {
          portal: "https://staging.portal.westore.ai/",
        },
    portalLinks: null as unknown as {
      signUp: string;
      account: string;
    },
  },
};
globals.dynamicUrls.portalLinks = {
  signUp: globals.dynamicUrls.baseUrls.portal + "register",
  account: globals.dynamicUrls.baseUrls.portal + "dashboard",
};

export default Vue.extend({
  data: () => ({
    globals: globals,
    isProduction: isProduction(),
  }),

  created: function () {},
  mounted: function () {},
  methods: {
    trackMyPageView() {
      setTimeout(() => {
        this.$gtag.pageview(<any>this.$route);
      }, 250);
    },

    logGAEvent(category: string, action: string, label?: string) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },

    logGAException(exception: any, fatal = false) {
      this.$gtag.exception({ description: exception, fatal });
    },
  },
});

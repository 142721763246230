







import Vue from "vue";
export default Vue.extend({
  name: "ws-gradient-text",
  //   props: {
  //     text: String,
  //   },
});

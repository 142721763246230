<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
  >
    <path
      d="M11.3279 0.466252C11.4047 0.387914 11.4963 0.32568 11.5975 0.283195C11.6986 0.24071 11.8072 0.218826 11.9169 0.218826C12.0265 0.218826 12.1351 0.24071 12.2363 0.283195C12.3374 0.32568 12.429 0.387914 12.5058 0.466252C12.8275 0.791377 12.832 1.31675 12.517 1.6475L5.86492 9.51125C5.78939 9.59419 5.69774 9.66084 5.59557 9.70714C5.49339 9.75344 5.38285 9.7784 5.2707 9.78051C5.15855 9.78262 5.04714 9.76182 4.94331 9.71939C4.83947 9.67696 4.74538 9.61379 4.66679 9.53375L0.619042 5.432C0.462933 5.2728 0.375488 5.05872 0.375488 4.83575C0.375488 4.61278 0.462933 4.39871 0.619042 4.2395C0.695818 4.16116 0.787453 4.09893 0.888579 4.05644C0.989705 4.01396 1.09829 3.99208 1.20798 3.99208C1.31767 3.99208 1.42625 4.01396 1.52738 4.05644C1.62851 4.09893 1.72014 4.16116 1.79692 4.2395L5.23042 7.71913L11.3054 0.491002C11.3124 0.482308 11.3199 0.474043 11.3279 0.466252Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

export const metadata = {
    Home: {
        title: "Home",

        description:
            "Easy on demand warehousing and delivery | Warehouse space for rent & same day delivery & multi-channel fulfillment in Dubai, Abu Dhabi, RAK, Sharjah, and UAE",
    },
    About: {
        title: "About",
        description:
            "Rent warehouse | same day delivery | multi-channel fulfillment in UAE",
    },
    Products: {
        title: "Products",
        description:
            "Our comprehensive storage and delivery services put convenience and growth at your fingertips – saving you piling up your home with boxes and wasting hours of back & forth in the car. And with automated billing and paperwork, you can focus on your next big venture.",
    },
    Clients: {
        title: "Clients",
        description: "",
    },
    ServiceProvider: {
        title: "ServiceProvider",
        description:
            "Turn dead space into live income. We’ll match you with suitable sellers, helping you generate additional earnings and grow your business.",
    },
    Integrations: {
        title: "Integrations",
        description:"",
    },
    Technology: {
        title: "Technology",
        description: "",
    },
    /**Start-Services****/
    Warehousing: {
        title: "Warehousing",
        description: "",
    },
    Fulfillment: {
        title: "Fulfillment",
        description: "",
    },
    LastMile: {
        title: "LastMile",
        description: "",
    },
    Freight: {
        title: "Freight",
        description: "",
    },
    Transportation: {
        title: "Transportation",
        description: "",
    },
    CustomsClearance: {
        title: "CustomsClearance",
        description: "",
    },
    ProjectManagement: {
        title: "ProjectManagement",
        description: "",
    },
    /**End-Services****/
    Contact: {
        title: "Contact",
        description:
            "Have questions about the WeStore Logistics Solutions? Let’s get in touch.",
    },
    Help: {
        title: "Help",
        description:
            "WeStore has your back and aims to deliver a seamless experience, every time.",
    },
    FAQs: {
        title: "FAQs",
        description:
            "Frequently asked questions. Easy, On-Demand Warehousing and Delivery",
    },
    "Privacy Policy": {
        title: "Privacy Policy",
        description:
            "WeStore respects your privacy and is committed to protecting your personal data.",
    },
    "Terms of Service": {
        title: "Terms of Service",
        description:
            "WeStore offers an online platform connecting warehouses with storage space and delivery services available with customers seeking to use such storage space and delivery services.",
    },
    "Terms and Conditions": {
        title: "Terms and Conditions",
        description:
            "WeStore offers an online platform connecting warehouses with storage space and delivery services available with customers seeking to use such storage space and delivery services.",
    },
    Plans: {
        title: "Subscription Plans",
        description:
            "WeStore offers an online platform connecting warehouses with storage space and delivery services available with customers seeking to use such storage space and delivery services.",
    },
    ThankYou: {
        title: "Thank You",
        description:
            "WeStore offers an online platform connecting warehouses with storage space and delivery services available with customers seeking to use such storage space and delivery services.",
    },
    LeadsServices: {
        title: "LeadsServices",
    },
    ChooseServices: {
        title: "ChooseServices",
    },
    // LANDINGS
    //  {
    //     title: "Easy, On-Demand Warehousing and Delivery",
    //     description: "E-Warehouse space for rent across UAE",
    //   },
    //   {
    //     title: "E-commerce Fulfillment Services Center in UAE",
    //     description: "E-commerce Fulfillment Services Center in UAE",
    //   },
};

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signUp-wrap"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isShow)?_c(VCard,_vm._b({staticClass:"changeNumber-card"},'v-card',_vm.$attrs,false),[_c(VCardText,{staticClass:"d-block text-center black--text"},[_c('h3',[_vm._v("CHANGE YOUR NUMBER")])]),_c(VCardText,{staticClass:"pt-0"},[_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"changeNumber-content"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-center mb-1"},[_vm._v("Enter your number")]),_c('VueTelInput',{attrs:{"defaultCountry":'AE',"preferred-countries":['AE', 'SA', 'EG', 'US'],"dropdownOptions":{
                      showDialCodeInList: true,
                      showFlags: true,
                      showSearchBox: true,
                    },"inputOptions":{
                      placeholder: '',
                      maxlength: 14,
                      required: true,
                      type: 'tel',
                    },"invalidMsg":'invalid number',"validCharactersOnly":true,"mode":'international'},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('button',{staticClass:"mt-7 ml-2 submit-btn",on:{"click":function($event){$event.preventDefault();return _vm.changePhoneNumber.apply(null, arguments)}}},[_vm._v(" Submit ")])])])],1)],1)],1)],1):_vm._e()],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.isShow)?_c(VCard,_vm._b({staticClass:"otp-card"},'v-card',_vm.$attrs,false),[_c(VCardText,{staticClass:"d-block text-center black--text"},[_c('h3',[_vm._v("VERIFY YOUR NUMBER")])]),_c(VCardText,{staticClass:"pt-0"},[_c(VForm,{ref:"form",staticClass:"signup-form",attrs:{"lazy-validation":""},model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center pr-lg-5 pl-lg-11 mt-2"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-center mb-1"},[_vm._v("Your Number")]),_c('VueTelInput',{attrs:{"defaultCountry":'AE',"preferred-countries":['AE', 'SA', 'EG', 'US'],"dropdownOptions":{
                      showDialCodeInList: true,
                      showFlags: true,
                      showSearchBox: true,
                    },"inputOptions":{
                      placeholder: '',
                      maxlength: 14,
                      required: true,
                      type: 'tel',
                    },"invalidMsg":'invalid number',"validCharactersOnly":true,"mode":'international',"disabled":true},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('a',{staticClass:"mt-7 ml-2",on:{"click":function($event){_vm.isShow = true}}},[_vm._v("Change")])])]),_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center otp-content"},[_c('p',[_vm._v("An OTP code has been sent to your number")]),(_vm.timerCount > 0)?_c('span',{staticClass:"otp-txt"},[_vm._v("OTP")]):_c('span',{staticClass:"otp-txt red--text"},[_vm._v("OTP expired")]),_vm._l((_vm.otpArray),function(item,index){return _c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.otpArray[index]),expression:"otpArray[index]"}],key:index,ref:("otpInput-" + index),refInFor:true,staticClass:"digit-box",attrs:{"type":"text","maxlength":"1"},domProps:{"value":(_vm.otpArray[index])},on:{"focus":function($event){_vm.otpArray[index] = ''},"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.otpArray, index, $event.target.value)},function($event){return _vm.otpInputListener($event, index)}]}})}),_c('div',{staticClass:"mb-2 mt-3 timer-wrapper"},[_c('span',[_vm._v("OTP expires in")]),_c('span',{staticClass:"timer"},[_vm._v("00:"+_vm._s(_vm.timerCount))])]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.resendOtp.apply(null, arguments)}}},[_vm._v("Resend OTP")])],2)])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
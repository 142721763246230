








































































































































import { types } from "@/services/westore/enums";
interface footerLink {
  icon?: string;
  title: string;
  url?: string;
  to?: { name: string; hash?: string } | any;
  action?: any;
}

interface PdfLink {
  name: string;
  path: string;
}

// const baseUrl = "https://westore.ai";
const baseUrl = "";

import mixins from "vue-typed-mixins";
import MyMixin, { globals } from "@/plugins/myMixin";
import wsWhatsappPopup from "./ws-whatsapp-popup.vue";

export default mixins(MyMixin).extend({
  components: { wsWhatsappPopup },
  name: "ws-footer",
  data() {
    return {
      socialIcons: [
        { url: "https://facebook.com/WeStoreAi", icon: "mdi-facebook" },
        { url: "https://www.instagram.com/westore.ai", icon: "mdi-instagram" },
        { url: "https://www.twitter.com/westoretech", icon: "mdi-twitter" },
        {
          url: "https://www.linkedin.com/company/westore-ai/",
          icon: "mdi-linkedin",
        },
      ],
      footerLinks: [
        // Company
        {
          head: { title: "Company", url: "/" },
          links: [
            { title: "About", to: { name: "About" } },
            { title: "Career", to: { name: "About", hash: "#career" } },
            {
              title: "Terms of Service",
              to: { name: "ToS" },
            },
            {
              title: "Privacy Policy",
              to: { name: "PP" },
            },
          ],
          pdfs: [
            {
              name: "Quality Policy",
              path: "https://drive.google.com/file/d/1Z2U_GGtAgaXvqkd2oBwxLufjucv2-7dP/view?usp=sharing",
            },
            {
              name: "Health and Safety Policy",
              path: "https://drive.google.com/file/d/1orkdYhwGL7JMZdsIFcBZkm1oUqUTN9l_/view?usp=sharing",
            },
            {
              name: "Environmental Policy",
              path: "https://drive.google.com/file/d/1DF72tqbN8Y05fJBhUwtQw8pvK9zeMznK/view?usp=sharing",
            },
          ],
        },
        //   Services
        {
          head: { title: "Services", url: "/" },
          links:
            this.$route.params.country == "ae"
              ? [
                  {
                    title: "Warehousing",
                    to: { name: "Warehousing" },
                  },
                  {
                    title: "Fulfillment",
                    to: { name: "Fulfillment" },
                  },
                  {
                    title: "Last Mile Delivery",
                    to: { name: "LastMile" },
                  },
                  {
                    title: "Freight",
                    to: { name: "Freight" },
                  },
                  {
                    title: "Transportation",
                    to: { name: "Transportation" },
                  },
                  {
                    title: "Customs Clearance",
                    to: { name: "CustomsClearance" },
                  },
                  {
                    title: "Project Management",
                    to: { name: "ProjectManagement" },
                  },
                ]
              : [
                  {
                    title: "Warehousing",
                    to: { name: "Warehousing" },
                  },
                  {
                    title: "Fulfillment",
                    to: { name: "Fulfillment" },
                  },
                  {
                    title: "Freight",
                    to: { name: "Freight" },
                  },
                  {
                    title: "Transportation",
                    to: { name: "Transportation" },
                  },
                  {
                    title: "Customs Clearance",
                    to: { name: "CustomsClearance" },
                  },
                  {
                    title: "Project Management",
                    to: { name: "ProjectManagement" },
                  },
                ],
        },
        //   Resources
        {
          head: { title: "Resources", to: { name: "Help", hash: "#help" } },
          links: [
            { title: "Help", to: { name: "Help" } },
            { title: "FAQs", to: { name: "FAQs" } },
            {
              title: "Schedule a Demo",

              action: () => {
                globals.interestDialog = true;
              },
            },
            {
              title: "List Your Warehouse",
              to: { name: "Warehousing", hash: "#list-your-warehouse" },
            },
          ],
        },
        //   Contact
        {
          cols: "3",
          head: { title: "Contact", to: { name: "Contact" } },
          links: [
            {
              title:
                this.$route.params.country == "ae"
                  ? globals.contactPoints.phone.text
                  : this.$route.params.country == "kw"
                  ? globals.contactPoints.phone.text2
                  : globals.contactPoints.phone.text3,
              url:
                this.$route.params.country == "ae"
                  ? globals.contactPoints.phone.href
                  : this.$route.params.country == "kw"
                  ? globals.contactPoints.phone.href2
                  : globals.contactPoints.phone.href3,
              icon: globals.contactPoints.phone.icon,
            },
            {
              title: globals.contactPoints.email.text,
              icon: globals.contactPoints.email.icon,
              url: globals.contactPoints.email.href,
            },
            {
              title:
                this.$route.params.country == "ae"
                  ? globals.contactPoints.stAddress.text
                  : this.$route.params.country == "kw"
                  ? globals.contactPoints.stAddress.text2
                  : globals.contactPoints.stAddress.text3,
              url:
                this.$route.params.country == "ae"
                  ? globals.contactPoints.stAddress.text
                  : this.$route.params.country === "kw"
                  ? globals.contactPoints.stAddress.text2
                  : globals.contactPoints.stAddress.text3,
              icon: globals.contactPoints.stAddress.icon,
            },
            {
              title:
                this.$route.params.country == "qa"
                  ? globals.contactPoints.fax.text3
                  : "",
              icon:
                this.$route.params.country == "qa"
                  ? globals.contactPoints.fax.icon
                  : "",
            },
          ],
        },
      ] as Array<{
        cols?: string;
        head: footerLink;
        links: footerLink[];
        pdfs?: PdfLink[];
      }>,
    };
  },
  mounted() {},
  computed: {
    types() {
      return types;
    },
  },
});

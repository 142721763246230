export const types = {
    ContactForm: 1,
    CustomerInterest: 2,
    Subscription: 3,
    VendorInterest: 4,
    Integration:5,
}

export const subjects = {
    1: 'Contact Form',
    2: 'Customer Interest',
    3: 'Subscription',
    4: 'Vendor Interest',
    5: 'Integration',
}

export const sub_types = {
    Starter: 1,
    Advanced: 2,
    Premium: 3,
    Custom: 4,
}

export const plans = {
    1: 'Starter',
    2: 'Advanced',
    3: 'Premium',
    4: 'Custom',
}
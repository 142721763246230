<template>
  <div>
    <!-- <v-fab-transition> -->
    <v-btn
      fab
      :large="!isMobile"
      :small="isMobile"
      color="whatsapp-green"
      bottom
      right
      fixed
      @click.native="showHideContacts"
      transition="fab-transition"
    >
      <!-- <v-fab-transition v-if="!globals.whatsappPopupVisible"> -->
      <v-icon
        large
        v-if="!globals.whatsappPopupVisible"
        transition="fab-transition"
      >
        mdi-whatsapp
      </v-icon>
      <!-- </v-fab-transition>
        <v-fab-transition v-else> -->
      <v-icon large v-else> mdi-close </v-icon>
      <!-- </v-fab-transition> -->
    </v-btn>
    <!-- </v-fab-transition> -->

    <!-- <v-slide-y-reverse-transition> </v-slide-y-reverse-transition> -->
    <v-scroll-y-reverse-transition>
      <v-card
        v-if="globals.whatsappPopupVisible"
        light
        class="ma-4 ws-whatsapp-card"
        max-width="350px"
        shaped
        v-click-outside="closeMe"
      >
        <v-list-item dark class="ws-whatsapp-card--title">
          <v-list-item-icon>
            <v-icon large> mdi-whatsapp </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <h3>Start a Conversation</h3>
            </v-list-item-title>

            <p class="body-2 font-weight-bold mb-0">
              <small>
                Hi! Click one of our member below to chat on
                <strong> WhatsApp </strong>
              </small>
            </p>
          </v-list-item-content>
        </v-list-item>

        <!-- Contacts -->
        <v-list dense shaped xrounded two-line>
          <v-subheader>
            The team typically replies in a few minutes.
          </v-subheader>
          <v-list-item
            v-for="(cnt, i) in contacts"
            :key="i"
            class="ws-whatsapp-card--contact ml-2 mb-2"
            @click="startChat"
            target="_blank"
            :href="`https://api.whatsapp.com/send?phone=${cnt.whatsappNumber}`"
          >
            <v-list-item-avatar>
              <v-img eager :src="cnt.profilePic" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold" v-text="cnt.title" />
              <v-list-item-subtitle v-text="cnt.name" />
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="mt-3" color="whatsapp-green">
                mdi-whatsapp
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </v-scroll-y-reverse-transition>
  </div>
</template>

<script>
import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";

export default mixins(MyMixin).extend({
  name: "ws-whatsapp-popup",
  props: {
    category: {
      type: String,
      default: 'Let\s Chat'
    },
    label: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    isMobile: false,
    contacts: [
      {
        name: "Wiam",
        title: "Sales & Support",
        profilePic: require("../assets/whatsapp/wiam.png"),
        whatsappNumber: "971585989361",
      },
      {
        name: "Ritu",
        title: "Sales & Support",
        profilePic: require("../assets/whatsapp/ritu.jpg"),
        whatsappNumber: "971585454161",
      },
      {
        name: "Ale",
        title: "Sales",
        profilePic: require("../assets/whatsapp/ale.png"),
        whatsappNumber: "971585466311",
      },
      // {
      //   name: "Ali",
      //   title: "Support",
      //   profilePic: require("../assets/whatsapp/ali.jpeg"),
      //   whatsappNumber: "971585954290",
      // },
    ],
  }),
  created() {
    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    showHideContacts() {
      if (!this.globals.whatsappPopupVisible) {
        this.logGAEvent( this.category,'Whatsapp open', this.getLabel());
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    },
    startChat() {
      this.logGAEvent( this.category,'Start WhatsApp Chat', this.getLabel());
    },
    closeMe() {
      this.globals.whatsappPopupVisible = false;
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    getLabel() {
      if (this.label !== null) {
        return this.label
      }

      return `${this.$route.name} Page`
    }
  },
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M28.8954 8.69395C28.5653 8.53301 28.176 8.53301 27.8438 8.69395L11.0616 16.7863C10.6502 16.9826 10.3896 17.3957 10.3896 17.8486V38.2586C10.3896 38.7116 10.6522 39.1272 11.0682 39.3255L27.8504 47.3107C28.0155 47.3889 28.1917 47.4287 28.3684 47.4287C28.5471 47.4287 28.7258 47.3884 28.8884 47.3107L45.6706 39.3255C46.0866 39.1267 46.3491 38.7136 46.3491 38.2586V17.8486C46.3491 17.3977 46.0891 16.9826 45.6772 16.7863L28.8954 8.69395ZM28.3684 24.5194L24.7067 22.7758L38.7657 16.0833L42.4073 17.8402L28.3684 24.5194ZM21.425 21.711C21.009 21.9098 20.7465 22.3229 20.7465 22.7778V26.6241L20.5996 26.3361C20.4189 25.9833 20.0705 25.7421 19.6747 25.6953C19.3153 25.6504 18.9578 25.7735 18.6978 26.0211V21.4069L34.3466 13.96L36.0247 14.7657L21.425 21.711ZM28.3684 11.0739L31.605 12.6321L17.4981 19.3445L14.3362 17.8397L28.3684 11.0739ZM12.7855 19.7307L16.3003 21.4029V29.1981C16.3003 29.6914 16.6103 30.1359 17.0808 30.3053C17.5513 30.4792 18.0784 30.3457 18.4085 29.9705L19.2723 28.9794L20.8783 32.0981C21.0863 32.5043 21.5023 32.7479 21.9481 32.7479C22.041 32.7479 22.1334 32.737 22.2263 32.7145C22.7644 32.585 23.1446 32.1096 23.1446 31.5625V24.6577L27.1728 26.5731V44.3566L12.7881 37.5121V19.7312L12.7855 19.7307ZM29.5677 44.3579V26.5744L43.9525 19.7299V37.5134L29.5677 44.3579Z"
      fill="#48BEEA"
    />
    <path
      d="M47.1251 16.1793L53.0078 18.8915C53.1683 18.9653 53.3405 19.0031 53.5146 19.0031C53.6999 19.0031 53.8857 18.9608 54.0528 18.8781C54.381 18.7151 54.6137 18.4137 54.6884 18.0589L56 11.7973L53.6498 11.3175L53.0465 14.2041C48.055 5.47996 38.7225 1.48224e-08 28.3689 1.48224e-08C12.7266 -0.000498245 0 12.561 0 28C0 43.439 12.727 56 28.3689 56C39.9285 56 50.2355 49.1804 54.6233 38.6298L52.4045 37.7304C48.3878 47.3917 38.9541 53.6362 28.3691 53.6362C14.0477 53.6337 2.39901 42.1346 2.39901 27.9999C2.39901 13.8652 14.047 2.36618 28.3678 2.36618C37.8309 2.36618 46.3598 7.3646 50.9376 15.3278L48.1422 14.0378L47.1251 16.1793Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style>
</style>
import { Request, Map } from '@/services/http/request';

const request = new Request(process.env.VUE_APP_BACKEND_PORTAL);

export interface FormEntry {
    // phone: String;
    code: String;
}

export class VerifyOtp {
    static uri: string = "/auth/otp/validate";
    static send(data: FormEntry) {
        const userToken = localStorage.getItem('userToken') ?? '';
        return request.withCredentials(userToken).post(VerifyOtp.uri, data);
    }
}

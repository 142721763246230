<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_671_634)">
      <path
        d="M13.899 4.89847C13.9513 4.84617 13.9927 4.78409 14.021 4.71575C14.0494 4.64742 14.0639 4.57418 14.0639 4.50022C14.0639 4.42626 14.0494 4.35302 14.021 4.28469C13.9927 4.21636 13.9513 4.15427 13.899 4.10197C13.8467 4.04967 13.7846 4.00819 13.7162 3.97988C13.6479 3.95158 13.5747 3.93701 13.5007 3.93701C13.4267 3.93701 13.3535 3.95158 13.2852 3.97988C13.2168 4.00819 13.1548 4.04967 13.1025 4.10197L5.62571 11.5798L2.08646 8.03947C2.03416 7.98717 1.97207 7.94569 1.90374 7.91738C1.83541 7.88908 1.76217 7.87451 1.68821 7.87451C1.53884 7.87451 1.39558 7.93385 1.28996 8.03947C1.18434 8.14509 1.125 8.28835 1.125 8.43772C1.125 8.58709 1.18434 8.73035 1.28996 8.83597L5.22746 12.7735C5.27971 12.8259 5.34178 12.8674 5.41012 12.8958C5.47846 12.9241 5.55172 12.9387 5.62571 12.9387C5.6997 12.9387 5.77296 12.9241 5.8413 12.8958C5.90964 12.8674 5.97171 12.8259 6.02396 12.7735L13.899 4.89847ZM9.16496 12.7735L8.15696 11.7643L8.95234 10.969L9.56321 11.5798L17.04 4.10197C17.1456 3.99635 17.2888 3.93701 17.4382 3.93701C17.5876 3.93701 17.7308 3.99635 17.8365 4.10197C17.9421 4.20759 18.0014 4.35085 18.0014 4.50022C18.0014 4.64959 17.9421 4.79285 17.8365 4.89847L9.96146 12.7735C9.90921 12.8259 9.84714 12.8674 9.7788 12.8958C9.71046 12.9241 9.6372 12.9387 9.56321 12.9387C9.48922 12.9387 9.41596 12.9241 9.34762 12.8958C9.27929 12.8674 9.21721 12.8259 9.16496 12.7735Z"
        fill="#65CD54"
      />
      <path
        d="M6.02396 8.03947L7.03196 9.0486L6.23659 9.84397L5.22746 8.83597C5.12184 8.73035 5.0625 8.5871 5.0625 8.43772C5.0625 8.28835 5.12184 8.14509 5.22746 8.03947C5.33308 7.93385 5.47634 7.87451 5.62571 7.87451C5.77508 7.87451 5.91834 7.93385 6.02396 8.03947Z"
        fill="#65CD54"
      />
    </g>
    <defs>
      <clipPath id="clip0_671_634">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

const axios = require('axios');

export interface Map {
    [key: string]: any
}

export class Request {
    responseType: string = 'json';
    response: Map = {};
    baseURL: string;
    headers: Map;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
        this.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
    }

    private async base(method: string, uri: string, payload: Map) {
        try {
            const context = this.buildContext(method, uri, payload);

            this.response = await axios(context);
            if (this.responseType !== 'json') {
                this.responseType = 'json';
            }
            return {
                data: this.response.data,
            };
        } catch (error) {
            return Promise.reject(error);
        }
    }

    buildContext(method: string, uri: string, payload: Map) {
        const context: Map = {};
        context.headers = this.headers;
        if (method === 'GET') {
            context.params = payload;
        } else {
            context.data = payload;
        }
        context.method = method;
        context.url = uri;
        context.baseURL = this.baseURL;
        context.responseType = this.responseType;

        return context;
    }

    post(uri: string, payload: Map){
        return this.base('POST', uri, payload)
    }

    get(uri: string, params: Map = {}){
        return this.base('GET', uri, params)
    }

    put(uri: string, payload: Map){
        return this.base('PUT', uri, payload)
    }

    setHeader(key: string, value: string) {
        this.headers[key] = value;
    }

    setHeaders(headers: Map) {
        this.headers = headers;
        return this;
    }

    withCredentials(bearer_token: string) {
        axios.default.withCredentials = true;
        this.setHeader('Authorization', bearer_token)
        return this;
    }

    hasFiles() {
        this.setHeader('Content-Type', 'multipart/form-data')
        return this;
    }

    blobResponse() {
        this.responseType = 'blob';
    }

    getResponse() {
        return this.response;
    }
}
<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_122_14756)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.09667 1.02882C7.12577 1.05785 7.14886 1.09233 7.16461 1.1303C7.18037 1.16827 7.18848 1.20897 7.18848 1.25007C7.18848 1.29117 7.18037 1.33188 7.16461 1.36984C7.14886 1.40781 7.12577 1.44229 7.09667 1.47132L3.56729 5.00007L7.09667 8.52882C7.12572 8.55788 7.14877 8.59237 7.16449 8.63033C7.18022 8.66829 7.18831 8.70898 7.18831 8.75007C7.18831 8.79116 7.18022 8.83185 7.16449 8.86981C7.14877 8.90777 7.12572 8.94227 7.09667 8.97132C7.06761 9.00038 7.03312 9.02342 6.99516 9.03915C6.9572 9.05487 6.91651 9.06297 6.87542 9.06297C6.83433 9.06297 6.79364 9.05487 6.75568 9.03915C6.71772 9.02342 6.68322 9.00038 6.65417 8.97132L2.90417 5.22132C2.87507 5.19229 2.85198 5.15781 2.83622 5.11984C2.82047 5.08188 2.81236 5.04118 2.81236 5.00007C2.81236 4.95897 2.82047 4.91827 2.83622 4.8803C2.85198 4.84233 2.87507 4.80785 2.90417 4.77882L6.65417 1.02882C6.6832 0.999718 6.71768 0.976629 6.75565 0.960875C6.79361 0.945121 6.83431 0.937012 6.87542 0.937012C6.91652 0.937012 6.95722 0.945121 6.99519 0.960875C7.03315 0.976629 7.06764 0.999718 7.09667 1.02882Z"
        fill="#3D4859"
      />
    </g>
    <defs>
      <clipPath id="clip0_122_14756">
        <rect
          width="10"
          height="10"
          fill="white"
          transform="translate(10) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

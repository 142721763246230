import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import {
  catalogIcon,
  paperworkIcon,
  reportingIcon,
  returnsIcon,
  seamlessIcon,
  trackingIcon,
  exclamationIcon,
  eyeslashIcon,
  chevronLeftIcon,
  circlecheckIcon,
  doublearrowIcon,
  homeIcon,
  warehousIcon,
  deliveryIcon,
  freightIcon,
  doublecheckIcon,
  wrongIcon,
  plusIcon,
  starsIcon,
  starIcon,
  doubleArrowDown,
  checkIcon,
  playIcon,
  paymentIcon,
  gradientCheck,
  integratedSales,
  closeIcon,
}
  from "@/assets/icons";
Vue.use(Vuetify);
export default new Vuetify({
  breakpoint: {
    thresholds: {
      // xs: 340,
      sm: 767,
      md: 1023,
      lg: 1280,
    },
    // scrollBarWidth: 24,
    // "sm": 0,
    // "md": 768px,
    // "lg": 1024px - 16px,
    // "xl": 1280px - 16px,
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#E33064",
        secondary: "#341436",
        accent: "#48BFEA",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        "ws-primary": "#101820",
        "ws-black": "#0C1922",
        "ws-green": "#49ce42",
        "ws-info": "#48BEEA",
        "ws-pink": "#E33065",
        "whatsapp-green": "#2db742",
      },
    },
  },
  icons: {
    iconfont: "mdi",
    values: {
      catalog: {
        component: catalogIcon,
      },
      paperwork: {
        component: paperworkIcon,
      },
      reporting: {
        component: reportingIcon,
      },
      returns: {
        component: returnsIcon,
      },
      seamless: {
        component: seamlessIcon,
      },
      tracking: {
        component: trackingIcon,
      },
      exclamationIcon: {
        component: exclamationIcon,
      },
      eyeslashIcon: {
        component: eyeslashIcon,
      },
      chevronLeftIcon: {
        component: chevronLeftIcon,
      },
      circlecheckIcon: {
        component: circlecheckIcon,
      },
      doublearrowIcon: {
        component: doublearrowIcon,
      },
      homeIcon: {
        component: homeIcon,
      },
      warehousIcon: {
        component: warehousIcon,
      },

      deliveryIcon: {
        component: deliveryIcon,
      },
      freightIcon: {
        component: freightIcon,
      },
      doublecheckIcon: {
        component: doublecheckIcon,
      },
      wrongIcon: {
        component: wrongIcon,
      },
      plusIcon: {
        component: plusIcon,
      },
      starsIcon: {
        component: starsIcon,
      },
      starIcon: {
        component: starIcon,
      },
      doubleArrowDown: {
        component: doubleArrowDown,
      },
      checkIcon: {
        component: checkIcon,
      },
      playIcon: {
        component: playIcon,
      },
      paymentIcon: {
        component: paymentIcon,
      },
      gradientCheck: {
        component: gradientCheck,
      },
      integratedSales: {
        component: integratedSales,
      },
      closeIcon: {
        component: closeIcon,
      },
    },
  },
});

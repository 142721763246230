<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="8"
    viewBox="0 0 15 8"
    fill="none"
  >
    <path
      d="M2.62335 7.25V0.949999H4.12135V7.25H2.62335ZM0.145352 4.8V3.4H6.59935V4.8H0.145352ZM10.8948 7.25V0.949999H12.3928V7.25H10.8948ZM8.41684 4.8V3.4H14.8708V4.8H8.41684Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

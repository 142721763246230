<template>
  <v-col class="p-0" cols="12">
    <div class="form-group">
      <label>Phone Number<span>*</span></label>
      <VueTelInput
        v-model="formData.phone"
        :class="{ is_invalid: $v.formData.phone.$error }"
        :defaultCountry="country"
        :preferred-countries="preferredCountries"
        :dropdownOptions="dropdownOptions"
        :inputOptions="inputOptions"
        :invalidMsg="'invalid number'"
        :validCharactersOnly="true"
        :mode="'international'"
        @blur="validatePhone"
      />
      <div
        v-if="!$v.formData.phone.required && $v.formData.phone.$dirty"
        class="errors"
      >
        Phone number is required.
      </div>
    </div>
  </v-col>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    country: {
      type: String,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueTelInput,
  },
  data() {
    return {
      preferredCountries: this.getPreferredCountries(),
    };
  },
  methods: {
    getPreferredCountries() {
      switch (this.country) {
        case "ae":
          return ["AE", "KW", "QA", "SA", "EG", "US"];
        case "kw":
          return ["KW", "QA", "AE", "SA", "EG", "US"];
        case "qa":
        default:
          return ["QA", "KW", "AE", "SA", "EG", "US"];
      }
    },
    validatePhone() {
      this.$v.formData.phone.$touch();
    },
  },
  computed: {
    dropdownOptions() {
      return {
        showDialCodeInList: true,
        showFlags: true,
        showSearchBox: true,
      };
    },
    inputOptions() {
      return {
        placeholder: "",
        maxlength: 14,
        required: true,
        type: "tel",
      };
    },
  },
  validations: {
    formData: {
      phone: { required },
    },
  },
};
</script>

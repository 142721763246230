<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.10178 1.85178C4.15403 1.7994 4.2161 1.75783 4.28444 1.72948C4.35278 1.70112 4.42604 1.68652 4.50003 1.68652C4.57402 1.68652 4.64728 1.70112 4.71562 1.72948C4.78396 1.75783 4.84603 1.7994 4.89828 1.85178L11.6483 8.60178C11.7007 8.65403 11.7422 8.7161 11.7706 8.78444C11.7989 8.85278 11.8135 8.92604 11.8135 9.00003C11.8135 9.07402 11.7989 9.14728 11.7706 9.21562C11.7422 9.28395 11.7007 9.34603 11.6483 9.39828L4.89828 16.1483C4.79266 16.2539 4.6494 16.3132 4.50003 16.3132C4.35066 16.3132 4.2074 16.2539 4.10178 16.1483C3.99616 16.0427 3.93682 15.8994 3.93682 15.75C3.93682 15.6007 3.99616 15.4574 4.10178 15.3518L10.4547 9.00003L4.10178 2.64828C4.0494 2.59603 4.00783 2.53396 3.97948 2.46562C3.95112 2.39728 3.93652 2.32402 3.93652 2.25003C3.93652 2.17604 3.95112 2.10278 3.97948 2.03444C4.00783 1.9661 4.0494 1.90403 4.10178 1.85178Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.60178 1.85178C8.65403 1.7994 8.7161 1.75783 8.78444 1.72948C8.85278 1.70112 8.92604 1.68652 9.00003 1.68652C9.07402 1.68652 9.14728 1.70112 9.21562 1.72948C9.28395 1.75783 9.34603 1.7994 9.39828 1.85178L16.1483 8.60178C16.2007 8.65403 16.2422 8.7161 16.2706 8.78444C16.2989 8.85278 16.3135 8.92604 16.3135 9.00003C16.3135 9.07402 16.2989 9.14728 16.2706 9.21562C16.2422 9.28395 16.2007 9.34603 16.1483 9.39828L9.39828 16.1483C9.29266 16.2539 9.1494 16.3132 9.00003 16.3132C8.85066 16.3132 8.7074 16.2539 8.60178 16.1483C8.49616 16.0427 8.43682 15.8994 8.43682 15.75C8.43682 15.6007 8.49616 15.4574 8.60178 15.3518L14.9547 9.00003L8.60178 2.64828C8.5494 2.59603 8.50783 2.53396 8.47948 2.46562C8.45112 2.39728 8.43652 2.32402 8.43652 2.25003C8.43652 2.17604 8.45112 2.10278 8.47948 2.03444C8.50783 1.9661 8.5494 1.90403 8.60178 1.85178Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

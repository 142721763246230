<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="70"
    viewBox="0 0 80 70"
    fill="none"
  >
    <path
      d="M1.21571 25.2855C1.50854 25.2855 1.70399 25.1881 1.99683 25.0901L8.92886 20.9896V68.5352C8.92886 69.3163 9.6126 69.9993 10.393 69.9993H21.9129L39.7791 70H69.1656C69.9467 70 70.6297 69.3163 70.6297 68.5358L70.6304 21.0879L77.5625 25.1884C77.7579 25.2858 78.0507 25.3838 78.3436 25.3838C78.8319 25.3838 79.3202 25.091 79.613 24.7001C80.0032 24.0164 79.8084 23.1379 79.1247 22.6495L40.5611 0.194767H40.4637C40.4637 0.194767 40.3663 0.194768 40.3663 0.0973842C40.3663 0.0973842 40.2689 0.0973836 40.2689 0H40.1715H39.9761H39.8787H39.7813H39.6839H39.5865C39.5865 0 39.4892 6.54656e-07 39.4892 0.0973842C39.4892 0.0973842 39.3918 0.0973836 39.3918 0.194767H39.2944L0.730788 22.6495C0.047051 23.0397 -0.245784 23.9189 0.242502 24.7001C0.239097 25.091 0.72737 25.2858 1.21566 25.2858L1.21571 25.2855ZM56.1811 67.0709H41.2438V56.7217H56.279V67.0709H56.1811ZM32.2619 43.4446H47.1992V53.7931L39.7795 53.7938H32.2619V43.4446ZM23.3781 56.7222H38.4134V67.0707L23.3781 67.0714V56.7222ZM67.7019 20.2088V67.0709H59.1103V27.6286C59.1103 26.8475 58.4266 26.1645 57.6461 26.1645H21.9138C21.1326 26.1645 20.4496 26.8482 20.4496 27.6286V67.0709H11.858V19.7206C11.858 19.6233 11.858 19.4278 11.7607 19.3304L39.7803 3.12377L67.8976 19.5252C67.7995 19.7206 67.7021 19.9161 67.7021 20.2089L67.7019 20.2088Z"
      fill="#C6C9CC"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

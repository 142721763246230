import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../pages/Home.vue";


Vue.use(VueRouter);

// @ts-ignore
// @ts-ignore
const routes: Array<RouteConfig> = [
  { path: "/sitemap.xml" },
  { path: "/robots.txt" },
  {
    path: '/plans',
    name: 'Subscriptions',
    component: () => import(/* webpackChunkName: "plans" */ '../pages/subscriptions/Home.vue')
  },
  {
    path: '/plans/thank-you',
    name: 'plan-thank-you',
    component: () => import(/* webpackChunkName: "plans" */ '../pages/subscriptions/ThankYou.vue')
  },
  {
    path: '/plans/terms-and-conditions',
    name: 'Terms and Conditions',
    component: () => import('../pages/legal/TermsAndConditions.vue')
  },
  {
    path: '/plans/:slug',
    name: 'Plan-details',
    component: () => import(/* webpackChunkName: "about" */ '../pages/subscriptions/Plan.vue')
  },
  {
    path: "/about-:country",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/About.vue"),
  },
  {
    path: "/products-:country",
    name: "Products",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "products" */ "../pages/Products.vue"),
  },
  {
    path: "/clients-:country",
    name: "Clients",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "clients" */ "../pages/Clients.vue"),
  },
  {
    path: "/serviceprovider-:country",
    name: "ServiceProvider",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "serviceprovider" */ "../pages/ServiceProvider.vue"),
  },
  {
    path: "/contact-:country",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../pages/Contact.vue"),
  },
  {
    path: "/technology-:country",
    name: "Technology",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "technology" */ "../pages/Technology.vue"),
  },
  {
    path: "/integrations-:country",
    name: "Integrations",
    component: () =>
      import("../pages/Integrations.vue"),
  },

  /***Start-Services*****/
  
  {
    path: "/warehousing-:country",
    name: "Warehousing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "services" */ "../pages/services/Warehousing.vue"),
  },
  {
    path: "/fulfillment-:country",
    name: "Fulfillment",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "services" */ "../pages/services/Fulfillment.vue"),
  },
  {
    path: "/freight-:country",
    name: "Freight",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "services" */ "../pages/services/Freight.vue"),
  },
  {
    path: "/lastMile-:country",
    name: "LastMile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "services" */ "../pages/services/LastMile.vue"),
  },
  {
    path: "/transportation-:country",
    name: "Transportation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "services" */ "../pages/services/Transportation.vue"),
  },
  {
    path: "/customs-clearance-:country",
    name: "CustomsClearance",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "services" */ "../pages/services/CustomsClearance.vue"),
  },
  {
    path: "/projectManagement-:country",
    name: "ProjectManagement",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "services" */ "../pages/services/ProjectManagement.vue"),
  },

  {
    path: "/faqs-:country",
    name: "FAQs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faqs" */ "../pages/FAQs.vue"),
  },

  {
    path: "/help-:country",
    name: "Help",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "help" */ "../pages/Help.vue"),
  },

  {
    path: "/easy-on-demand-warehousing-and-delivery",
    name: "Warehouse Performance",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "landing1" */ "../pages/landing/Landing-1.vue"
      ),
  },

  {
    path: "/supply-chain",
    name: "Supply Chain",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
            /* webpackChunkName: "landing1" */ "../pages/landing/SupplyChain.vue"
      ),
  },
  {
    path: "/warehousing-storage",
    name: "Warehousing & Storage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
            /* webpackChunkName: "landing1" */ "../pages/landing/WarehousingStorage.vue"
      ),
  },
  {
    path: "/fulfilment",
    name: "Fulfilment",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
            /* webpackChunkName: "landing1" */ "../pages/landing/Fulfilment.vue"
      ),
  },
  {
    path: "/social-sellers",
    name: "Do You Sell Online ?",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "landing1" */ "../pages/landing/SocialSellerLanding.vue"
      ),
  },
  {
    path: "/last-mile-delivery",
    name: "Are you looking for a last mile delivery ?",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "landing1" */ "../pages/landing/LastMileDelivery.vue"
      ),
  },
  {
    path: "/ff",
    name: "Are you a freight forwarder and need a short-term storage ?",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "landing1" */ "../pages/landing/FreightForwarders.vue"
      ),
  }, {
    path: "/ecommerce",
    name: "Do you have an Ecommerce business ?",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "landing1" */ "../pages/landing/Ecommerce.vue"
      ),
  },
  {
    path: "/noon-integration",
    name: "Do you want to Connect Your Noon marketplace with WeStore ?",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
            /* webpackChunkName: "landing1" */ "../pages/landing/NoonEcommerce.vue"
      ),
  },

  {
    path: "/e-commerce-fulfillment-services-center-in-uae",
    name: "Fulfillment performance",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "landing2" */ "../pages/landing/Landing-2.vue"
      ),
  },
  {
    path: "/samples",
    name: "zLab",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "zLab" */ "../pages/zLab.vue"),
  },
  {
    path: "/terms-of-service-:country",
    name: "ToS",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "legal" */ "../pages/legal/ToS.vue"),
  },
  {
    path: "/privacy-policy-:country",
    name: "PP",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited. PP
    component: () =>
      import(/* webpackChunkName: "legal" */ "../pages/legal/PP.vue"),
  },
  /**Go-type-form***/
  {
    name: '/type-form',
    path: '/type-form/:id',
    beforeEnter(to, from) {
      window.location.href = (`https://9hp0jlrihm5.typeform.com/to/Pxdh1mNZ#id=${to.query.id}`);
      console.log(to);
    }
  },
  /**Leads-Thank-You***/
  {
    path: "/thank-you",
    name: "leads-thank-you",
    component: () =>
      import(/* webpackChunkName: "Thank" */ "../pages/leads/Thank.vue"),
  },
  /**Leads-Choose-Services***/
  {
    path: "/leads-service-orders-volume",
    name: "leads-service-orders-volume",
    component: () =>
      import(/* webpackChunkName: "leads-services" */ "../pages/leads/ChooseService.vue"),
  },

  /**Leads-price-plans-Services***/
  {
    path: "/choose-plans/:pricing",
    name: "choose-plans",
    component: () =>
      import(/* webpackChunkName: "choose-plans" */ "../pages/leads/ChoosePlan.vue"),
  },
  /**Leads-confirm-price-plans***/
  {
    path: "/confirm-plan/:plan",
    name: "confirm-plan",
    component: () =>
      import(/* webpackChunkName: "confirm-plan" */ "../pages/leads/ConfirmPlan.vue"),
  },
  /**New-Landing-Pages***/
  {
    path: "/pages/:slug",
    name: "new-landing",
    props: true,
    component: () =>
      import(/* webpackChunkName: "new-landing" */ "../pages/new-landing/new-landing.vue"),
  },
  /***404-Page***/
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../components/notfound.vue"),
  },
  {
    path: "/:country",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter:(to,from,next)=>{      
      next({name:'Home',params:{country:'kw'}});
      }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      // return { selector: to.hash, behavior: "smooth" };
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ selector: to.hash, behavior: "smooth" });
        }, 250);
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.afterEach((to, from) => {
});
export default router;

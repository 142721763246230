<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.44006 5.81524C1.4807 5.7745 1.52897 5.74217 1.58213 5.72011C1.63528 5.69806 1.69226 5.68671 1.74981 5.68671C1.80735 5.68671 1.86433 5.69806 1.91748 5.72011C1.97064 5.74217 2.01892 5.7745 2.05956 5.81524L6.99981 10.7564L11.9401 5.81524C12.0222 5.73309 12.1336 5.68694 12.2498 5.68694C12.366 5.68694 12.4774 5.73309 12.5596 5.81524C12.6417 5.89739 12.6879 6.00881 12.6879 6.12499C12.6879 6.24117 12.6417 6.35259 12.5596 6.43474L7.30956 11.6847C7.26892 11.7255 7.22064 11.7578 7.16748 11.7799C7.11433 11.8019 7.05735 11.8133 6.99981 11.8133C6.94226 11.8133 6.88528 11.8019 6.83213 11.7799C6.77897 11.7578 6.7307 11.7255 6.69006 11.6847L1.44006 6.43474C1.39931 6.3941 1.36699 6.34582 1.34493 6.29267C1.32288 6.23952 1.31152 6.18253 1.31152 6.12499C1.31152 6.06744 1.32288 6.01046 1.34493 5.95731C1.36699 5.90416 1.39931 5.85588 1.44006 5.81524Z"
      fill="#48BEEA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.44006 2.31524C1.4807 2.2745 1.52897 2.24217 1.58213 2.22012C1.63528 2.19806 1.69226 2.18671 1.74981 2.18671C1.80735 2.18671 1.86433 2.19806 1.91748 2.22012C1.97064 2.24217 2.01892 2.2745 2.05956 2.31524L6.99981 7.25636L11.9401 2.31524C11.9807 2.27456 12.029 2.2423 12.0822 2.22028C12.1353 2.19827 12.1923 2.18694 12.2498 2.18694C12.3073 2.18694 12.3643 2.19827 12.4174 2.22028C12.4706 2.2423 12.5189 2.27456 12.5596 2.31524C12.6002 2.35592 12.6325 2.40421 12.6545 2.45735C12.6765 2.5105 12.6879 2.56746 12.6879 2.62499C12.6879 2.68251 12.6765 2.73948 12.6545 2.79262C12.6325 2.84577 12.6002 2.89406 12.5596 2.93474L7.30956 8.18474C7.26892 8.22548 7.22064 8.25781 7.16748 8.27986C7.11433 8.30192 7.05735 8.31327 6.99981 8.31327C6.94226 8.31327 6.88528 8.30192 6.83213 8.27986C6.77897 8.25781 6.7307 8.22548 6.69006 8.18474L1.44006 2.93474C1.39931 2.8941 1.36699 2.84582 1.34493 2.79267C1.32288 2.73952 1.31152 2.68254 1.31152 2.62499C1.31152 2.56744 1.32288 2.51046 1.34493 2.45731C1.36699 2.40416 1.39931 2.35588 1.44006 2.31524Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";
import { isProduction } from "./plugins/siteBase";
import './plugins/bootstrap';
import './plugins/slider';
import Toast, { TYPE } from "vue-toastification";
import toastFailIcon from "@/assets/icons/toastFailIcon.vue";
import toastSuccessIcon from "@/assets/icons/toastSuccessIcon.vue";
import Vuelidate from "vuelidate";

import 'vue-tel-input/dist/vue-tel-input.css';
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;

import wsAppBar from "@/components/ws-app-bar.vue";
Vue.component("ws-app-bar", wsAppBar);

import wsGradientText from "@/components/ws-gradient-text.vue";
Vue.component("ws-gradient-text", wsGradientText);

import wsFooter from "@/components/ws-footer.vue";
Vue.component("ws-footer", wsFooter);

import wsCommonForm from "@/components/ws-common-form.vue";
Vue.component("ws-common-form", wsCommonForm);


const options = {
  position: "top-center",
  transition: "fadeToast",
  toastDefaults: {
    [TYPE.ERROR]: {
      icon: toastFailIcon,
      toastClassName: "toast-error",
    },
    [TYPE.SUCCESS]: {
      icon: toastSuccessIcon,
      toastClassName: "toast-success",
    },
  },
};


Vue.use(VueGtag, {
  config: {
    id: isProduction() ? "UA-210915830-1" : "UA-210915830-2",
    params: {
      send_page_view: false,
    },
  },
});

Vue.use(VueMeta);
Vue.use(Toast, options);
Vue.use(Vuelidate);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="62"
    viewBox="0 0 56 62"
    fill="none"
  >
    <path
      d="M28.6259 18.0505C28.6259 13.8631 25.2561 10.4567 21.1143 10.4567C16.9718 10.4567 13.6018 13.863 13.6018 18.0505C13.6018 22.2375 16.9716 25.6435 21.1143 25.6435C25.2561 25.6435 28.6259 22.2371 28.6259 18.0505ZM20.4883 11.7541V17.6847L15.4071 20.6384C15.0548 19.8476 14.854 18.9725 14.854 18.0502C14.8535 14.774 17.3289 12.0724 20.4883 11.7543L20.4883 11.7541ZM16.0336 21.7327L21.7404 18.416L21.74 11.7541C24.899 12.0723 27.3739 14.7739 27.3739 18.0505C27.3739 21.5394 24.5654 24.3783 21.1139 24.3783C19.0218 24.3779 17.1704 23.3316 16.0335 21.7327H16.0336Z"
      fill="#48BEEA"
    />
    <path
      d="M36.1788 15.7233H31.2251V25.6396H36.1788V15.7233ZM34.9272 24.3744H32.4767V16.9884H34.9272V24.3744Z"
      fill="#48BEEA"
    />
    <path
      d="M38.1848 25.6568H43.1385V11.3682H38.1848V25.6568ZM39.4364 12.6334H41.8869V24.3916H39.4364V12.6334Z"
      fill="#48BEEA"
    />
    <path
      d="M20.5254 35.8221H36.2156V30.5155H20.5254V35.8221ZM21.777 31.7803H34.9638V34.557H21.777V31.7803Z"
      fill="#48BEEA"
    />
    <path
      d="M15.8191 40.6935H40.9197V41.9587H15.8191V40.6935Z"
      fill="#48BEEA"
    />
    <path d="M19.8916 45.4846H36.847V46.7498H19.8916V45.4846Z" fill="#48BEEA" />
    <path d="M24.446 50.2745H32.2951V51.5397H24.446V50.2745Z" fill="#48BEEA" />
    <path
      d="M55.7929 43.1845L47.4334 26.9911V7.19729C47.4334 6.18805 46.621 5.36726 45.6221 5.36726H36.2703L34.0058 0.982595C33.5435 0.0880835 32.4481 -0.25874 31.5632 0.206794L21.7811 5.36735H11.1174C10.119 5.36735 9.30701 6.1886 9.30701 7.19738V11.9471L0.973385 16.3437C0.544678 16.5696 0.228594 16.9509 0.084097 17.4178C-0.060411 17.8843 -0.0180604 18.3794 0.206341 18.8136L9.30755 36.4417V54.8008C9.30755 55.81 10.12 56.6308 11.118 56.6308H19.7298L21.9934 61.0155C22.2169 61.4488 22.5941 61.7679 23.056 61.9144C23.235 61.9713 23.4184 62 23.6004 62C23.8881 62 24.1736 61.9298 24.436 61.7913L34.2191 56.6316H45.6232C46.6221 56.6316 47.4346 55.8104 47.4346 54.8016V49.6614L55.0274 45.6562C55.4566 45.4298 55.7718 45.0476 55.9167 44.5821C56.0604 44.1125 56.0176 43.6174 55.7932 43.1845L55.7929 43.1845ZM32.1418 1.3284C32.416 1.1841 32.7539 1.29177 32.8958 1.56759L34.8578 5.36672H24.4862L32.1418 1.3284ZM1.31571 18.2267C1.24673 18.0935 1.2332 17.9394 1.27773 17.796C1.3227 17.6517 1.42048 17.5339 1.55233 17.4651L9.30669 13.3742V33.7058L1.31571 18.2267ZM23.8569 60.6669C23.7255 60.7379 23.5736 60.7525 23.4313 60.7053C23.2885 60.6603 23.172 60.5619 23.1034 60.4281L21.1428 56.629H31.5135L23.8569 60.6669ZM46.1825 54.799C46.1825 55.1101 45.9323 55.3639 45.6228 55.3639H11.1186C10.8099 55.3639 10.5598 55.111 10.5598 54.799V7.19605C10.5598 6.88405 10.8099 6.63119 11.1186 6.63119H45.6228C45.9314 6.63119 46.1825 6.88405 46.1825 7.19605V54.799ZM54.7217 44.1995C54.6772 44.3438 54.579 44.4617 54.4476 44.531L47.4341 48.2308V29.7249L54.6842 43.7695C54.7536 43.9028 54.7671 44.0559 54.7217 44.1994L54.7217 44.1995Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style>
</style>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_114_13439)">
      <path
        d="M13.359 11.238C15.06 9.72 16 8 16 8C16 8 13 2.5 7.99996 2.5C7.03955 2.50331 6.09002 2.70342 5.20996 3.088L5.97996 3.859C6.6274 3.62315 7.31091 3.50168 7.99996 3.5C10.12 3.5 11.879 4.668 13.168 5.957C13.7883 6.58069 14.3444 7.26513 14.828 8C14.77 8.087 14.706 8.183 14.633 8.288C14.298 8.768 13.803 9.408 13.168 10.043C13.003 10.208 12.831 10.371 12.651 10.529L13.359 11.238Z"
        fill="#C6C9CC"
      />
      <path
        d="M11.2969 9.17611C11.52 8.55195 11.5613 7.87726 11.416 7.23053C11.2707 6.5838 10.9448 5.99164 10.476 5.52293C10.0073 5.05422 9.41517 4.72825 8.76845 4.58294C8.12172 4.43763 7.44702 4.47896 6.82287 4.70211L7.64587 5.52511C8.03018 5.4701 8.42202 5.50535 8.79034 5.62807C9.15866 5.75079 9.49334 5.9576 9.76785 6.23212C10.0424 6.50664 10.2492 6.84132 10.3719 7.20964C10.4946 7.57796 10.5299 7.9698 10.4749 8.35411L11.2969 9.17611ZM8.35387 10.4751L9.17587 11.2971C8.55171 11.5203 7.87701 11.5616 7.23029 11.4163C6.58356 11.271 5.99139 10.945 5.52269 10.4763C5.05398 10.0076 4.72801 9.41542 4.5827 8.76869C4.43738 8.12196 4.47871 7.44727 4.70187 6.82311L5.52487 7.64611C5.46986 8.03042 5.50511 8.42226 5.62783 8.79058C5.75055 9.1589 5.95736 9.49358 6.23188 9.7681C6.5064 10.0426 6.84107 10.2494 7.20939 10.3721C7.57772 10.4949 7.96956 10.5301 8.35387 10.4751Z"
        fill="#C6C9CC"
      />
      <path
        d="M3.35 5.47C3.17 5.63 2.997 5.792 2.832 5.957C2.21165 6.58069 1.65552 7.26512 1.172 8L1.367 8.288C1.702 8.768 2.197 9.408 2.832 10.043C4.121 11.332 5.881 12.5 8 12.5C8.716 12.5 9.39 12.367 10.02 12.14L10.79 12.912C9.90994 13.2965 8.9604 13.4967 8 13.5C3 13.5 0 8 0 8C0 8 0.939 6.279 2.641 4.762L3.349 5.471L3.35 5.47ZM13.646 14.354L1.646 2.354L2.354 1.646L14.354 13.646L13.646 14.354Z"
        fill="#C6C9CC"
      />
    </g>
    <defs>
      <clipPath id="clip0_114_13439">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>




































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";

import { ContactRequest } from "@/integration/contact-form";
import { required, minLength, email } from "vuelidate/lib/validators";
import WsOtpForm from "./ws-otp-form.vue";
import PhoneNumberInput from "./PhoneNumberInput.vue";

export interface ErrorResponse {
  [key: string]: string[];
}
export default mixins(MyMixin).extend({
  name: "ws-common-form",
  props: {
    title: String,
    titleColor: String,
    submitTitle: String,
    subtitle: String,
    formType: Number,
  },

  data: () => ({
    showPassword: false,
    isShow: false,
    submitting: false,
    otpPhone: "",
    country: "",
    formData: {
      name: "",
      email: "",
      phone: "",
      password: "",
      checkbox1: false,
      checkbox2: false,
    },
  }),
  created() {
    this.country = this.$route.params.country.toLowerCase();
  },
  components: {
    WsOtpForm,
    PhoneNumberInput,
  },
  computed: {
    hasEmptyFields(): boolean {
      return (
        this.formData.name.trim() === "" ||
        this.formData.email.trim() === "" ||
        this.formData.phone.trim() === "" ||
        this.formData.password.trim() === "" ||
        !this.formData.checkbox1 ||
        !this.formData.checkbox2
      );
    },
    theForm(): any {
      return this.$refs.form;
    },
  },
  validations: {
    formData: {
      name: { required, minLength: minLength(3) },
      email: { required, email },
      phone: { required },
      password: { required, minLength: minLength(8) },
      checkbox1: { required },
      checkbox2: { required },
    },
  },
  methods: {
    submitForm() {
      this.logGAEvent(
        "Signup",
        `Submit`,
        (this.$route.name as string) + " Page"
      );

      this.submitting = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitting = false;
        return;
      }

      ContactRequest.send({
        type: this.formType,
        first_name: this.formData.name,
        last_name: "",
        email: this.formData.email,
        phone: this.formData.phone,
        password: this.formData.password,
        "g-recaptcha": "tempCode",
        utm_source: this.$route.query.utm_source as string,
        utm_medium: this.$route.query.utm_medium as string,
        utm_campaign: this.$route.query.utm_campaign as string,
        utm_content: this.$route.query.utm_content as string,
        page_name: this.$route.name as string,
      })
        .then(({ data }) => {
          // Clear
          (this.otpPhone = this.formData.phone.replaceAll(" ", "")),
            (this.formData = {
              name: "",
              email: "",
              phone: "",
              password: "",
              checkbox1: false,
              checkbox2: false,
            });

          localStorage.setItem("userToken", `Bearer ${data.data.token}`);
          this.isShow = true;
          this.$emit("success", this.formType);
        })
        .catch((err: any) => {
          const responseData = err.response?.data as ErrorResponse | undefined;

          if (responseData && Object.keys(responseData).length > 0) {
            const firstErrorKey = Object.keys(responseData)[0];
            const firstError = responseData[firstErrorKey];

            if (firstError) {
              // Check specific error messages and take appropriate actions
              if (firstErrorKey === "email_already_taken") {
                this.$toast.error("The email has already been taken.");
                // Perform additional actions if needed
              } else if (firstErrorKey === "phone_already_taken") {
                this.$toast.error("The phone has already been taken.");
                // Perform additional actions if needed
              } else {
                // Handle other error cases
                this.$toast.error(firstError.join());
              }

              // Redirect to login page in case of known errors
              if (
                firstErrorKey === "email_already_taken" ||
                firstErrorKey === "phone_already_taken"
              ) {
                window.location.href = "https://portal.westore.ai/login";
              }
            }
          }

          this.$emit("error", this.formType);
          this.logGAException(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
});

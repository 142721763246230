<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <g clip-path="url(#clip0_209_5913)">
      <path
        d="M16.7246 4.11328C13.7793 4.11328 11.0625 4.83691 8.57422 6.28418C6.08594 7.73145 4.11816 9.69922 2.6709 12.1875C1.22363 14.6758 0.5 17.3926 0.5 20.3379C0.5 23.2832 1.22363 26 2.6709 28.4883C4.11816 30.9766 6.08594 32.9443 8.57422 34.3916C11.0625 35.8389 13.7793 36.5625 16.7246 36.5625C19.6699 36.5625 22.3867 35.8389 24.875 34.3916C27.3633 32.9443 29.3311 30.9766 30.7783 28.4883C32.2256 26 32.9492 23.2832 32.9492 20.3379C32.9492 17.3926 32.2256 14.6758 30.7783 12.1875C29.3311 9.69922 27.3633 7.73145 24.875 6.28418C22.3867 4.83691 19.6699 4.11328 16.7246 4.11328ZM16.7246 33.3633C14.3887 33.3633 12.2178 32.7793 10.2119 31.6113C8.20605 30.4434 6.61914 28.8564 5.45117 26.8506C4.2832 24.8447 3.69922 22.6738 3.69922 20.3379C3.69922 18.002 4.2832 15.8311 5.45117 13.8252C6.61914 11.8193 8.20605 10.2324 10.2119 9.06445C12.2178 7.89648 14.3887 7.3125 16.7246 7.3125C19.0605 7.3125 21.2314 7.89648 23.2373 9.06445C25.2432 10.2324 26.8301 11.8193 27.998 13.8252C29.166 15.8311 29.75 18.002 29.75 20.3379C29.75 22.6738 29.166 24.8447 27.998 26.8506C26.8301 28.8564 25.2432 30.4434 23.2373 31.6113C21.2314 32.7793 19.0605 33.3633 16.7246 33.3633ZM24.1895 13.1777L13.4492 23.8418L9.25977 19.6523L6.97461 21.9375L13.4492 28.4883L26.4746 15.4629L24.1895 13.1777Z"
        fill="url(#paint0_linear_209_5913)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_209_5913"
        x1="0.5"
        y1="20.3379"
        x2="32.9492"
        y2="20.3379"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E33064" />
        <stop offset="1" stop-color="#48BFEA" />
      </linearGradient>
      <clipPath id="clip0_209_5913">
        <rect
          width="39"
          height="39"
          fill="white"
          transform="matrix(1 0 0 -1 0.5 39.8379)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>


































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { VueTelInput } from "vue-tel-input";
import { ChangePhone } from "@/integration/change-phone";
import { VerifyOtp } from "@/integration/verify-otp";
import { ResendOtp } from "@/integration/resend-otp";

export default mixins(MyMixin).extend({
    props: {
    otpPhone: {
      type: String,
      default: "",
    },
    formIsValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      phone: this.otpPhone,
      otpArray: new Array(6).fill(""),
      timerCount: 60,
      isShow: false,
    };
  },
  components: {
    VueTelInput,
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
        }
      },
      immediate: true,
    },
  },
  methods: {
    changePhoneNumber() {
      ChangePhone.send({
        phone: this.phone.replaceAll(" ", ""),
      })
        .then(({ data }) => {
          this.$toast.success(
            "Phone changed successfully, check your phone to get your OTP code."
          );
          this.logGAEvent(
            "OTP",
            `Phone Changed`,
            (this.$route.name as string) + " Page"
          );
          this.resetTimer();
          this.isShow = false;
        })
        .catch((err: any) => {
          this.$toast.error("The phone number invalid or used before !");
          this.$emit("error", "ChangeOtpPhone");
          this.logGAException(err);
          this.logGAEvent(
            "OTP",
            `Phone Changed-Failed`,
            (this.$route.name as string) + " Page"
          );
        });
    },
    submitOtpCode() {
      VerifyOtp.send({
        code: this.otpArray.join(""),
      })
        .then(({ data }) => {
          this.$toast.success("Verified Successfully!");
          this.logGAEvent(
            "OTP",
            `OTP Verified`,
            (this.$route.name as string) + " Page"
          );
          setTimeout(
            () => this.$router.push({ name: "leads-service-orders-volume" }),
            2000
          );
        })
        .catch((err: any) => {
          this.$toast.error("This Code is Invalid , please try again!");
          this.$emit("error", "VerifyOtp");
          this.logGAException(err);
          this.logGAEvent(
            "OTP",
            `OTP Failed`,
            (this.$route.name as string) + " Page"
          );
        });
    },
    resendOtp() {
      ResendOtp.send({
        phone: this.phone,
      })
        .then(({ data }) => {
          this.$toast.success("check your phone to get your OTP code!");
          this.logGAEvent(
            "OTP",
            `OTP Re-Sent`,
            (this.$route.name as string) + " Page"
          );
          this.resetTimer();
        })
        .catch((err: any) => {
          this.$toast.error("This Code is Invalid , please try again!");
          this.$emit("error", "ResendOtp");
          this.logGAException(err);
          this.logGAEvent(
            "OTP",
            `OTP Re-Sent Failed`,
            (this.$route.name as string) + " Page"
          );
        });
    },
    otpInputListener($event: any, index: number) {
      if (index != 5) {
        $event.target.nextElementSibling.focus();
      } else {
        this.submitOtpCode();
      }
    },
    resetTimer() {
      this.timerCount = 60;
    },
  },
});

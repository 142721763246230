<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="73"
    height="70"
    viewBox="0 0 73 70"
    fill="none"
  >
    <path
      d="M71.1943 67.6657H57.1886C56.7218 66.9655 56.2549 66.1485 56.1382 65.3315H37.2305C36.9971 66.1485 36.6469 66.9655 36.1801 67.6657H26.8429C26.3761 66.9655 25.9092 66.1485 25.7925 65.3315C25.7925 65.3315 21.2406 66.0317 21.0072 61.7133V40.9382C21.0072 38.9541 22.6412 37.3201 24.6254 37.3201H49.953C54.1547 37.4368 53.5711 41.9886 53.5711 41.9886H60.574V30.9841H11.6714V67.6657H9.33713V19.8129C9.33713 19.2294 9.9207 18.6458 10.5043 18.6458H61.8585C62.4421 18.6458 63.0257 19.2294 63.0257 19.8129V41.9886C63.8427 41.9886 64.7764 42.3388 65.3599 42.9223V17.4787L36.1814 4.99023L7.00285 17.4787V67.6657H1.16714C0.583571 67.6657 0 68.2493 0 68.8329C0 69.4165 0.583571 70 1.16714 70H71.1956C71.7792 70 72.3628 69.4165 72.3628 68.8329C72.3628 68.2493 71.7792 67.6657 71.1956 67.6657H71.1943ZM31.0446 9.19128L36.1801 6.85699L38.9812 8.02414C39.5648 8.25756 39.9149 8.95785 39.6815 9.54142C39.4481 10.125 38.7478 10.4751 38.1642 10.2417L36.1801 9.42471L31.9783 11.2921C31.8616 11.4088 30.9279 11.6423 30.4611 10.7086C30.1109 10.125 30.4611 9.42471 31.0446 9.19128Z"
      fill="#C6C9CC"
    />
    <path
      d="M65.3583 64.1645C65.3583 62.1804 63.841 60.6631 61.8569 60.6631C59.8728 60.6631 58.3555 62.1804 58.3555 64.1645C58.3555 66.1487 59.8728 67.6659 61.8569 67.6659C63.841 67.6659 65.3583 66.1487 65.3583 64.1645ZM61.8569 65.3317C61.2733 65.3317 60.6898 64.7481 60.6898 64.1645C60.6898 63.5809 61.2733 62.9974 61.8569 62.9974C62.4405 62.9974 63.024 63.5809 63.024 64.1645C63.024 64.7481 62.4405 65.3317 61.8569 65.3317Z"
      fill="#C6C9CC"
    />
    <path
      d="M58.3555 51.3256H68.8597L63.9577 44.7896C63.7243 44.5562 63.3742 44.3228 63.024 44.3228H58.3555V51.3256Z"
      fill="#C6C9CC"
    />
    <path
      d="M53.6875 44.3228V62.997H56.1385C56.7221 60.3126 59.0564 58.3285 61.8575 58.3285C64.6586 58.3285 66.9929 60.3126 67.5765 62.997H68.9771C69.5606 62.997 70.0275 62.5302 70.0275 61.9466V53.6599H57.1889C56.6054 53.6599 56.0218 53.0763 56.0218 52.4927V44.3228H53.6875Z"
      fill="#C6C9CC"
    />
    <path
      d="M35.0126 64.1645C35.0126 62.1804 33.4953 60.6631 31.5112 60.6631C29.527 60.6631 28.0098 62.1804 28.0098 64.1645C28.0098 66.1487 29.527 67.6659 31.5112 67.6659C33.4953 67.6659 35.0126 66.1487 35.0126 64.1645ZM31.5112 65.3317C30.9276 65.3317 30.344 64.7481 30.344 64.1645C30.344 63.5809 30.9276 62.9974 31.5112 62.9974C32.0948 62.9974 32.6783 63.5809 32.6783 64.1645C32.6783 64.7481 32.0948 65.3317 31.5112 65.3317Z"
      fill="#C6C9CC"
    />
    <path
      d="M23.3418 61.7141C23.3418 62.4144 23.9254 62.998 24.6257 62.998H25.7928C26.3764 60.3135 28.7106 58.3294 31.5118 58.3294C34.3129 58.3294 36.6472 60.3135 37.2308 62.998H51.3539V55.9951H23.3425L23.3418 61.7141ZM40.8489 58.3294H47.8518C48.4353 58.3294 49.0189 58.913 49.0189 59.4965C49.0189 60.0801 48.4353 60.6637 47.8518 60.6637H40.8489C40.2654 60.6637 39.6818 60.0801 39.6818 59.4965C39.6818 58.913 40.2654 58.3294 40.8489 58.3294Z"
      fill="#C6C9CC"
    />
    <path
      d="M51.3532 40.9377C51.3532 40.2374 50.7696 39.6538 50.0693 39.6538H24.6257C23.9254 39.6538 23.3418 40.2374 23.3418 40.9377V53.6595H51.3532V40.9377ZM29.1775 44.3224H28.0104V45.4895C28.0104 46.0731 27.4268 46.6567 26.8432 46.6567C26.2597 46.6567 25.6761 46.0731 25.6761 45.4895V43.1552C25.6761 42.5717 26.2597 41.9881 26.8432 41.9881H29.1775C29.7611 41.9881 30.3446 42.5717 30.3446 43.1552C30.3446 43.7388 29.7611 44.3224 29.1775 44.3224Z"
      fill="#C6C9CC"
    />
    <path
      d="M3.96709 16.1941L36.1802 2.53787L68.3933 16.1941C68.9769 16.4275 69.6772 16.1941 69.9106 15.6105C70.144 15.0269 69.9106 14.3267 69.327 14.0932L36.6471 0.0875356C36.2969 -0.0291785 36.0635 -0.0291785 35.7133 0.0875356L3.03337 14.0932C2.4498 14.3267 2.21637 15.0269 2.4498 15.6105C2.68323 16.1941 3.38352 16.4275 3.96709 16.1941Z"
      fill="#C6C9CC"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="70"
    viewBox="0 0 54 70"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.0751 10.7603H41.1452C41.9487 10.7603 42.5962 11.4078 42.5962 12.2172V13.7806C42.5962 14.5841 41.9487 15.2303 41.1452 15.2303H36.0751C35.2716 15.2303 34.6202 14.5841 34.6202 13.7806V12.2172C34.6202 11.4071 35.2702 10.7603 36.0751 10.7603ZM24.0919 10.7603H29.1607C29.9694 10.7603 30.6175 11.4078 30.6175 12.2172V13.7806C30.6175 14.5841 29.97 15.2303 29.1607 15.2303H24.0919C23.2884 15.2303 22.6402 14.5841 22.6402 13.7806V12.2172C22.6402 11.4071 23.2884 10.7603 24.0919 10.7603ZM12.1132 10.7603H17.1833C17.9868 10.7603 18.633 11.4078 18.633 12.2172V13.7806C18.633 14.5841 17.9868 15.2303 17.1833 15.2303H12.1132C11.3097 15.2303 10.657 14.5841 10.657 13.7806V12.2172C10.6564 11.4071 11.3097 10.7603 12.1132 10.7603ZM26.6272 0C24.4104 0 23.0627 1.4081 22.6245 2.50285L20.8082 7.04439H10.4074C9.03762 7.04439 8.2139 8.1762 7.93764 9.5206L6.49704 16.4786C6.22271 17.8165 7.60285 18.9483 8.97326 18.9483H44.2851C45.6542 18.9483 47.0355 17.8152 46.7548 16.4786L45.3207 9.5206C45.0392 8.17622 44.2149 7.04439 42.8445 7.04439H32.4508L30.6331 2.50285C30.1963 1.4081 28.8479 0 26.6273 0H26.6272Z"
      fill="#C6C9CC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.0675 24.3136H47.1376C47.9411 24.3136 48.5886 24.9598 48.5886 25.7633V27.3333C48.5886 28.1368 47.9411 28.7843 47.1376 28.7843H42.0675C41.2588 28.7843 40.6107 28.1368 40.6107 27.3333V25.7633C40.6107 24.9598 41.2588 24.3136 42.0675 24.3136ZM30.0823 24.3136H35.1524C35.9559 24.3136 36.6035 24.9598 36.6035 25.7633V27.3333C36.6035 28.1368 35.956 28.7843 35.1524 28.7843H30.0823C29.2788 28.7843 28.6326 28.1368 28.6326 27.3333V25.7633C28.6326 24.9598 29.2788 24.3136 30.0823 24.3136ZM18.1057 24.3136H23.1706C23.9741 24.3136 24.6255 24.9598 24.6255 25.7633V27.3333C24.6255 28.1368 23.9741 28.7843 23.1706 28.7843H18.1057C17.2969 28.7843 16.6488 28.1368 16.6488 27.3333V25.7633C16.6488 24.9598 17.2969 24.3136 18.1057 24.3136ZM6.12115 24.3136H11.1912C11.9948 24.3136 12.6429 24.9598 12.6429 25.7633V27.3333C12.6429 28.1368 11.9948 28.7843 11.1912 28.7843H6.12115C5.31243 28.7843 4.66429 28.1368 4.66429 27.3333V25.7633C4.66299 24.9598 5.31178 24.3136 6.12115 24.3136ZM4.39001 21.2705C3.02026 21.2705 1.91895 22.3763 1.91895 23.7468V30.4174C1.91895 31.7884 3.01956 32.8891 4.39001 32.8891H5.78186L3.71071 39.2307L22.8794 31.4484C24.1022 30.9458 25.3648 30.6988 26.6324 30.7027C27.8955 30.7027 29.1476 30.951 30.3698 31.4484L49.5452 39.2403L47.474 32.8876H48.8659C50.2356 32.8876 51.3421 31.787 51.3421 30.4159V23.7453C51.3421 22.3762 50.2363 21.269 48.8659 21.269H4.39077L4.39001 21.2705Z"
      fill="#C6C9CC"
    />
    <path
      d="M23.8759 33.92L1.4253 43.0401C-0.543161 43.8385 -0.139467 44.642 0.658835 46.5091L9.50925 67.1423C10.2374 68.8378 11.9367 70.0001 13.3605 70.0001H39.8718C41.2916 70.0001 42.9949 68.8423 43.7295 67.1423L52.5746 46.5091C53.3729 44.642 53.7831 43.8385 51.8146 43.0401L29.3607 33.92C27.2303 33.0508 25.9951 33.0508 23.8752 33.92H23.8759Z"
      fill="#C6C9CC"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

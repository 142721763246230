








import Vue from "vue";
import wsAppBar from "./components/ws-app-bar.vue";

export default Vue.extend({
  components: { wsAppBar },
  name: "App",
  metaInfo: {
    title: "WeStore",
    titleTemplate: "%s | WeStore",
  },
  data: () => ({
    //
  }),
  methods: {},
});






















































































































































































































































































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";

export default mixins(MyMixin).extend({
  name: "ws-app-bar",
  props: {
    dark: {
      type: Boolean,
    },
    transparent: {
      type: Boolean,
    },
  },
  data() {
    return {
      mobileMenu: false,
      loading: false,
      ua: "ae",
      kw: "kw",
      qa: "qa",
      selectedCountry: {
        title: "UAE",
        image: require("../assets/new-site/ae.png"),
      },
      selectedCountry2: {
        title: "KWT",
        image: require("../assets/new-site/kw.png"),
      },
      selectedCountry3: {
        title: "QAT",
        image: require("../assets/new-site/QA.png"),
      },
      navbar: {
        products: { title: "Products", target: { name: "Products" } },
        technology: { title: "Technology", target: { name: "Technology" } },
        integrations: {
          title: "Integrations",
          target: { name: "Integrations" },
        },
        clients: { title: "Clients", target: { name: "Clients" } },
        services:
          this.$route.params.country == "ae"
            ? [
                { title: "Warehousing", target: { name: "Warehousing" } },
                { title: "Fulfillment", target: { name: "Fulfillment" } },
                { title: "Last Mile Delivery", target: { name: "LastMile" } },
                { title: "Freight", target: { name: "Freight" } },
                { title: "Transportation", target: { name: "Transportation" } },
                {
                  title: "Customs Clearance",
                  target: { name: "CustomsClearance" },
                },
                {
                  title: "Project Management",
                  target: { name: "ProjectManagement" },
                },
              ]
            : [
                { title: "Warehousing", target: { name: "Warehousing" } },
                { title: "Fulfillment", target: { name: "Fulfillment" } },
                { title: "Freight", target: { name: "Freight" } },
                { title: "Transportation", target: { name: "Transportation" } },
                {
                  title: "Customs Clearance",
                  target: { name: "CustomsClearance" },
                },
                {
                  title: "Project Management",
                  target: { name: "ProjectManagement" },
                },
              ],
        company: [
          { title: "About", target: { name: "About" } },
          { title: "Contact Us", target: { name: "Contact" } },
          { title: "Service Provider", target: { name: "ServiceProvider" } },
        ],
        country: [
          {
            id: 1,
            title: "UAE",
            image: require("../assets/new-site/ae.png"),
          },
          {
            id: 2,
            title: "kuwait",
            image: require("../assets/new-site/kw.png"),
          },
          {
            id: 3,
            title: "Qatar",
            image: require("../assets/new-site/QA.png"),
          },
        ],
      },
    };
  },

  methods: {
    changeCountry(id: any) {
      if (id == 1) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 2000);
        this.$router.replace({
          params: { country: this.ua },
        });
        location.reload();
      } else if(id==2){
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 2000);
        this.$router.replace({
          params: { country: this.kw },
        });
        location.reload();
      }
      else {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 2000);
        this.$router.replace({
          params: { country: this.qa },
        });
        location.reload();
      }
    },
  },
});

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="53"
    viewBox="0 0 100 53"
    fill="none"
  >
    <path
      d="M94.0442 28.6783C95.11 28.6262 96.207 29.2934 96.8304 30.3768C97.6875 31.8657 97.3862 33.6471 96.16 34.3601C94.9333 35.0681 93.2441 34.4379 92.3865 32.9491C91.5281 31.4583 91.8245 29.6737 93.0569 28.9645C93.3526 28.7931 93.6847 28.7002 94.0443 28.6783L94.0442 28.6783ZM69.1368 10.257H74.6979C76.0801 10.257 76.6421 10.257 77.4059 11.5218L83.6437 22.3218C84.4789 23.7128 84.0228 24.2067 82.7446 24.2067H69.1175C68.0405 24.2067 67.5994 23.8376 67.5994 22.6908V11.793C67.5981 10.81 67.8437 10.257 69.1369 10.257L69.1368 10.257ZM24.6582 0C22.4696 0 20.6973 1.08276 20.6973 3.65565V39.7108C20.6973 42.2838 22.4597 44.355 24.6582 44.355H28.8986C28.8986 38.3783 33.738 33.5237 39.7101 33.5237C42.5734 33.5237 45.3288 34.6705 47.3557 36.701C49.3839 38.731 50.5216 41.4864 50.5216 44.355H66.3002C66.3002 38.3783 71.1396 33.5237 77.1054 33.5237C79.9757 33.5237 82.7242 34.6705 84.7523 36.701C86.7786 38.731 87.9158 41.4864 87.9158 44.355H96.5695C98.4729 44.355 100 42.5605 100 40.3309V31.2244C100 29.6514 99.5481 28.6616 98.5124 27.8243L91.5077 22.627C90.338 21.681 89.6056 20.7903 88.8518 19.5406L81.517 6.72255C77.64 -0.040172 72.9673 0.000642399 68.0621 0.000642399L24.6582 0Z"
      fill="#C6C9CC"
    />
    <path
      d="M77.1028 52.6821C72.5142 52.6821 68.7861 48.9536 68.7861 44.3501C68.7861 39.7529 72.5134 36.0181 77.1028 36.0181C79.3132 36.0181 81.4272 36.8981 82.9873 38.4597C84.5474 40.0227 85.4195 42.1424 85.4195 44.3486C85.4183 48.9546 81.6992 52.6817 77.1028 52.6817V52.6821ZM77.1028 47.4761C78.8284 47.4761 80.2217 46.0745 80.2217 44.3515C80.2217 42.6284 78.8283 41.2269 77.1028 41.2269C75.3842 41.2269 73.9851 42.6279 73.9851 44.3515C73.9851 46.0752 75.3848 47.4761 77.1028 47.4761Z"
      fill="#C6C9CC"
    />
    <path
      d="M39.7093 52.6821C35.1193 52.6821 31.3926 48.9536 31.3926 44.3501C31.3926 39.7529 35.1192 36.0181 39.7093 36.0181C41.9128 36.0181 44.0287 36.8981 45.5874 38.4597C47.1474 40.0227 48.0245 42.1424 48.0245 44.3486C48.0252 48.9546 44.2991 52.6817 39.7091 52.6817L39.7093 52.6821ZM39.7093 47.4761C41.4298 47.4761 42.8281 46.0745 42.8281 44.3515C42.8281 42.6284 41.4297 41.2269 39.7093 41.2269C37.9889 41.2269 36.5904 42.6279 36.5904 44.3515C36.5904 46.0752 37.9889 47.4761 39.7093 47.4761Z"
      fill="#C6C9CC"
    />
    <line
      x1="30.957"
      y1="10.7837"
      x2="1.0001"
      y2="10.7837"
      stroke="#C6C9CC"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="37.6846"
      y1="19.1934"
      x2="7.72764"
      y2="19.1934"
      stroke="#C6C9CC"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="43.4033"
      y1="27.603"
      x2="13.4464"
      y2="27.603"
      stroke="#C6C9CC"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>

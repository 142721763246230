import Westore from "@/services/westore";

export interface FormEntry {
  type: Number;
  // name?: String;
  email: String;
  phone: String;
  first_name?: string;
  last_name?: string;
  password?: string;
  "g-recaptcha"?: string;
  utm_source: String;
  utm_medium: String;
  utm_campaign: String;
  utm_content: String;
  page_name: String;
}

export class ContactRequest {
  static uri: string = "/register";
  static send(data: FormEntry) {
    return Westore.create(data, ContactRequest.uri);
  }
}
